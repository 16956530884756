import React, { useState } from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
// import { apiQuery } from "../libs/auth-config";
import Visible from "../components/Visible";
import { PREFIX } from "../constants";
import DeviceGroupAutocomplete from "../components/DeviceGroupAutocomplete";
// import { RbacContext } from "./LoadingContainer";

const MasqueradeUserContainer = (props) => {
  const deviceGroups = useSelector((state) => state.deviceGroups.accountGroups);
  const [selectedGroup, setSelectedGroup] = useState("");
  // const visible = useContext(RbacContext).Masquerade.view;
  const switchAccount = (event) => {
    updateUser();
  };

  const { rootFolderId } = useSelector(
    (state) => state.ui_settings.portal_config
  );

  const permissions = useSelector((state) => state.roles.permissions);
  const hasRoot = permissions.services.find((p) => p.id === rootFolderId);

  const add_super_to_device_groups = (deviceGroups) => {
    const merged = [
      {
        name: "All Accounts",
        account_id: PREFIX.toUpperCase() + "_SUPER",
      },
      ...deviceGroups,
    ];
    return hasRoot ? merged : deviceGroups;
  };

  async function updateUser() {
    if (selectedGroup.account_id) {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        "custom:account_id": selectedGroup.account_id,
        "custom:folder_id":
          selectedGroup.name === "All Accounts"
            ? String(rootFolderId)
            : selectedGroup.id.toString(),
      });
      Auth.currentSession()
        .then((data) => {
          const jwtToken = data.idToken.jwtToken;
          localStorage.setItem("jwt", jwtToken);
          window.location.reload();
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <>
      {/* {visible && ( */}
      <Visible component={"MasqueradeUserContainer"}>
        {deviceGroups.length ? (
          <Grid container>
            <Grid item sm={9}>
              <DeviceGroupAutocomplete
                onChange={(value) => setSelectedGroup(value)}
                deviceGroups={add_super_to_device_groups(deviceGroups)}
              />
            </Grid>
            <Grid item sm>
              <Button
                fullWidth
                disabled={selectedGroup ? false : true}
                variant="contained"
                onClick={switchAccount}
              >
                Switch
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item sm>
              <CircularProgress size={25} />
            </Grid>
            <Grid item sm={10}>
              <Typography color="primary">Loading...</Typography>
            </Grid>
          </Grid>
        )}
      </Visible>
      {/* )} */}
    </>
  );
};

export default MasqueradeUserContainer;
