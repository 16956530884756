import {
  createStyles,
  Divider,
  IconButton,
  Tab,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { DateRange, Note } from "@material-ui/icons";
import { Alert, TabContext, TabList, TabPanel } from "@material-ui/lab";
import { getUnixTime } from "date-fns";
import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import ContainerHeader from "../components/ContainerHeader";
import { LinkDevices } from "../components/dialogs/LinkDevices";
import SubmitTicket from "../components/dialogs/SubmitTicket";
import TimeRangeDialog from "../components/dialogs/TimeRangeDialog";
import PageRefreshInput from "../components/PageRefreshInput";
import Visible from "../components/Visible";
import DeviceContainer from "./DeviceContainer";
import { removeProperty } from "../actions/devices";
import RemovalConfirmation from "../components/dialogs/RemovalConfirmation";

const styles = (theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(1),
      flexGrow: 1,
      "& .MuiBox-root": {
        paddingTop: 20,
      },
      "& .MuiAlert-icon": {
        marginRight: 0,
        marginLeft: 3,
      },
      "& .MuiAlert-message": {
        marginLeft: 3,
        marginRight: 3,
      },
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    actions: { display: "flex", alignItems: "center", marginLeft: 5 },
    prelive: {
      marginRight: theme.spacing(1),
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 8,
      paddingRight: 6,
      cursor: "pointer",
    },
  });

class CircuitsContainer extends Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    var device = this.props.device;
    var tab = 0;
    if (device && device.Links) {
      device.Links.forEach((link, i) =>
        device.id === link.id ? (tab = i) : null
      );
    }
    this.state = {
      tab: tab,
      device_1: {},
      device_2: {},
      timeRangeDialogVisible: false,
      rangesChanged: false,
      fromDate: yesterday,
      fromEpoch: getUnixTime(yesterday),
      toDate: today,
      toEpoch: getUnixTime(today),
      dateType: "24 Hours",
      device_1_update: false,
      device_2_update: false,
      linkDialogState: false,
      active_device: device && device.id,
      preLiveDialogOpen: false,
      preLiveRemoving: false,
      preLiveAlertMessage: "Pre-Live",
    };
  }

  componentDidMount = () => {
    this.evaluateWrapping();
    window.addEventListener("resize", this.evaluateWrapping);
  };

  handleTimeRangeClosure = () => {
    this.setState({
      timeRangeDialogVisible: false,
    });
  };

  setTimeRangeDialogVisible = (state) => {
    this.setState({
      timeRangeDialogVisible: state,
    });
  };

  setStartDate = (date) => {
    var hasChanged = false;
    if (this.state.fromEpoch !== getUnixTime(date)) {
      hasChanged = true;
    }
    this.setState({
      rangesChanged: hasChanged,
      fromDate: date,
      fromEpoch: getUnixTime(date),
    });
  };

  setDateType = (type) => {
    this.setState({
      dateType: type.replaceAll("_", " "),
    });
  };

  setEndDate = (date) => {
    var hasChanged = false;
    if (this.state.fromEpoch !== getUnixTime(date)) {
      hasChanged = true;
    }
    this.setState({
      rangesChanged: hasChanged,
      toDate: date,
      toEpoch: getUnixTime(date),
    });
  };

  handleDataRangeOpen = (state) => {
    this.setState({
      timeRangeDialogVisible: true,
    });
  };

  handleTicketClosure = () => {
    this.setState({
      submitState: false,
    });
  };

  handleTicketButtonClick = () => {
    this.setState({
      submitState: true,
    });
  };

  handleLinkDialog = (state) => {
    this.setState({
      linkDialogState: state,
    });
  };

  handlePreLiveClick = () =>
    this.setState({
      preLiveDialogOpen: true,
    });

  handlePreLiveClose = () =>
    this.setState({
      preLiveDialogOpen: false,
    });

  disablePreLive = async () => {
    this.setState({ preLiveRemoving: true });
    const payload = {
      id: this.props.device.id,
      prop: "Pre-Live",
    };
    await this.props.removeProperty(payload);
    this.setState({
      preLiveRemoving: false,
      preLiveDialogOpen: false,
    });
  };

  evaluateWrapping = () => {
    if (window.innerWidth < 958) this.setState({ preLiveAlertMessage: "" });
    else this.setState({ preLiveAlertMessage: "Pre-Live" });
  };

  render() {
    const {
      tab,
      fromEpoch,
      toEpoch,
      dateType,
      rangesChanged,
      device_1_update,
      active_device,
      timeRangeDialogVisible,
      fromDate,
      toDate,
      submitState,
      preLiveDialogOpen,
      preLiveRemoving,
      preLiveAlertMessage,
    } = this.state;
    const { device, byId, links, device_page_title, classes } = this.props;
    const menuItems = [
      "Today",
      "24 Hours",
      "Week",
      "30 Days",
      "This Calendar Month",
      "Last Calendar Month",
      "Year",
      "Custom",
    ];

    if (!device) return null;
    return (
      <div className={classes.root}>
        <RemovalConfirmation
          open={preLiveDialogOpen}
          title={"Disable Pre-Live"}
          loading={preLiveRemoving}
          message={
            "You are about to manually remove the device from Pre-Live. This cannot be undone."
          }
          callBack={this.disablePreLive}
          closesureCallback={this.handlePreLiveClose}
        />

        <TimeRangeDialog
          visible={timeRangeDialogVisible}
          callbackClose={() => this.handleTimeRangeClosure()}
          callbackOpen={() => this.setTimeRangeDialogVisible(true)}
          setStartDate={(date) => this.setStartDate(date)}
          setEndDate={(date) => this.setEndDate(date)}
          setDateType={(date) => this.setDateType(date)}
          startDate={fromDate}
          endDate={toDate}
          menuItems={menuItems}
          disabled={false}
          extraConfig={false}
          dialogTitle={"Date Range"}
          timeRangeDisabled={false}
        />
        <SubmitTicket
          state={submitState}
          account={this.account}
          device={byId[active_device]}
          callbackFunction={() => this.handleTicketClosure()}
          // refreshTicketList={() => callback()}
        />
        <TabContext value={tab}>
          <div
            className={classes.header}
            style={{ paddingBottom: !links ? 10 : 0 }}
          >
            {links !== undefined ? (
              <div style={{ display: "flex" }}>
                <ContainerHeader custom={"Back To Inventory"} />
                <TabList
                  onChange={(_, newTab) => this.setState({ tab: newTab })}
                  style={{ marginBottom: 20 }}
                >
                  {links.map((link, i) => (
                    <Tab
                      label={link.tag}
                      value={i}
                      onClick={() => this.setState({ active_device: link.id })}
                    />
                  ))}
                </TabList>
              </div>
            ) : (
              <ContainerHeader
                title={device[device_page_title]}
                custom={"Back To Inventory"}
              />
            )}

            <div className={classes.actions}>
              {device["Pre-Live"] === "true" && (
                <Tooltip title="Remove from Pre-Live">
                  <Alert
                    severity="info"
                    variant="outlined"
                    className={classes.prelive}
                    onClick={this.handlePreLiveClick}
                  >
                    {preLiveAlertMessage}
                  </Alert>
                </Tooltip>
              )}
              <PageRefreshInput
                disabled={rangesChanged}
                refreshFunction={() => {
                  this.setState({
                    ...(tab
                      ? { device_2_update: true }
                      : { device_1_update: true }),
                  });
                }}
              />
              <Typography
                style={{ display: "inline-block" }}
                color="textSecondary"
                //gutterBottom
              >
                {dateType}
              </Typography>
              <Tooltip disableFocusListener title="Select Date Range">
                <IconButton onClick={() => this.handleDataRangeOpen()}>
                  <DateRange color="primary" />
                </IconButton>
              </Tooltip>
              <Visible
                component={"TicketInfoButton"}
                permissionNeeded="services.raiseTicket"
                capabilityNeeded="manage"
              >
                <Tooltip disableFocusListener title="Raise Ticket">
                  <IconButton
                    onClick={() => this.handleTicketButtonClick(true)}
                  >
                    <Note color="primary" />
                  </IconButton>
                </Tooltip>
              </Visible>
              <LinkDevices device={device} />
            </div>
          </div>
          <Divider />
          {links !== undefined ? (
            links.map(
              (link, i) =>
                byId[link.id] && (
                  <TabPanel value={i} style={{ padding: 0 }}>
                    <DeviceContainer
                      fromEpoch={fromEpoch}
                      toEpoch={toEpoch}
                      dateType={dateType}
                      device={byId[link.id]}
                      rangesChanged={rangesChanged}
                      forceUpdate={device_1_update}
                      removeCallback={this.setRemove}
                      completeUpdate={() =>
                        this.setState({ device_1_update: false })
                      }
                    />
                  </TabPanel>
                )
            )
          ) : (
            <DeviceContainer
              fromEpoch={fromEpoch}
              toEpoch={toEpoch}
              dateType={dateType}
              device={device}
              rangesChanged={rangesChanged}
              forceUpdate={device_1_update}
              completeUpdate={() => this.setState({ device_1_update: false })}
            />
          )}
        </TabContext>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { byId } = state.devices;
  const device = byId && byId[ownProps.match.params.id];
  const links = device && device.Links;
  const { device_page_title } = state.ui_settings.portal_config;
  return { device, byId, links, device_page_title };
}

const mapDispatchToProps = {
  removeProperty,
};

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(CircuitsContainer);
